(function () {
  'use strict';

  /**
   * @ngdoc object
   *
   * @description
   *
   */
  angular
    .module('events.reports')
    .controller('DonationsCtrl', DonationsCtrl)
    .controller('DisbursementsCtrl', DisbursementsCtrl)
    .controller('EntryFeesCtrl', EntryFeesCtrl)
    .controller('FrontEndCtrl', FrontEndCtrl)
    .controller('IndemnityCtrl', IndemnityCtrl)
    .controller('OptionCtrl', OptionCtrl)
    .controller('DietaryCtrl', DietaryCtrl)
    .controller('GuestsCtrl', GuestsCtrl)
    .controller('RoadbookCtrl', RoadbookCtrl)
    .controller('SafetyCtrl', SafetyCtrl)
    .controller('VirginsCtrl', VirginsCtrl)
    .controller('MedicalCtrl', MedicalCtrl)
    .controller('VaccinationsCtrl', VaccinationsCtrl);

  function DonationsCtrl(API_URL, Papa, FileSaver, Blob, $http, event) {
    var vm = this;

    vm.event = event.data;
    vm.vehicles = null;
    vm.download = download;

    vm.order = '-owing';

    $http.get(API_URL + '/reports/donations/' + vm.event.id).then(function (response) {

      var vehicles = [],
        vehicle = {};

      angular.forEach(response.data, function (v) {
        vehicle = {
          number: v.vehicle_number,
          team: v.team,
          captain: v.captain,
          paid: v.paid / 100,
          owing: Math.max(v.donation - v.paid, 0) / 100
        };

        vehicles.push(vehicle);
      });

      vm.vehicles = vehicles;
    });

    function download() {
      var data = [];

      angular.forEach(vm.vehicles, function (v) {
        data.push(["'" + v.number, v.team, v.captain, v.paid, v.owing]);
      });

      var contents = Papa.unparse({
        fields: ["Vehicle", "Team", "Captain", "Paid", "Owing"],
        data: data
      });

      var blob = new Blob([contents], {type: 'text/csv'});
      FileSaver.saveAs(blob, 'Donations Paid Owing.csv');
    }
  }

  function DisbursementsCtrl(API_URL, Papa, FileSaver, Blob, $http, event) {
    var vm = this;

    vm.event = event.data;
    vm.vehicles = null;
    vm.download = download;

    vm.order = '-owing';

    $http.get(API_URL + '/reports/disbursements/' + vm.event.id).then(function (response) {

      var vehicles = [],
        vehicle = {};

      angular.forEach(response.data, function (v) {
        vehicle = {
          number: v.vehicle_number,
          captain: v.captain,
          crew: v.crew_count,
          paid: v.paid / 100,
          owing: (v.total - v.paid) / 100
        };

        vehicles.push(vehicle);
      });

      vm.vehicles = vehicles;
    });

    function download() {
      var data = [];

      angular.forEach(vm.vehicles, function (v) {
        data.push(["'" + v.number, v.captain, v.crew, v.paid, v.owing]);
      });

      var contents = Papa.unparse({
        fields: ["Vehicle", "Captain", "Crew", "Paid", "Owing"],
        data: data
      });

      var blob = new Blob([contents], {type: 'text/csv'});
      FileSaver.saveAs(blob, 'Disbursements Paid Owing.csv');
    }
  }

  function OptionCtrl(API_URL, Papa, FileSaver, Blob, $http, event) {
    var vm = this;

    vm.event = event.data;
    vm.people = null;
    vm.download = download;
    vm.order = 'name';

    $http.get(API_URL + '/events/' + vm.event.id + '/vehicles?inc=VehiclePeople.Person').then(function (response) {

      var people = [],
        person = {};

      angular.forEach(response.data.data, function (v) {

        if (v.vehicle_people) {
          angular.forEach(v.vehicle_people, function (vp) {
            if (!vp.option) {
              return;
            }

            person = {
              name: vp.person.first_name + ' ' + vp.person.surname,
              vehicle_number: v.number,
              mobile_phone: vp.person.mobile_phone,
              email: vp.person.email
            };

            people.push(person);
          });
        }
      });

      vm.people = people;
    });

    function download() {
      var data = [];

      angular.forEach(vm.people, function (p) {
        data.push([p.name, "'" + p.vehicle_number, "'" + p.mobile_phone, p.email]);
      });

      var contents = Papa.unparse({
        fields: ["Name", "Vehicle", "Mobile Phone", "Email"],
        data: data
      });

      var blob = new Blob([contents], {type: 'text/csv'});
      FileSaver.saveAs(blob, 'Option Participants.csv');
    }
  }

  function EntryFeesCtrl(API_URL, Papa, FileSaver, Blob, $http, event) {
    var vm = this;

    vm.event = event.data;
    vm.vehicles = null;
    vm.download = download;

    vm.order = '-owing';

    $http.get(API_URL + '/reports/entry-fee/' + vm.event.id).then(function (response) {

      var vehicles = [],
        vehicle = {};

      angular.forEach(response.data, function (v) {
        vehicle = {
          number: v.vehicle_number,
          captain: v.captain,
          paid: v.paid / 100,
          owing: Math.max(v.total - v.paid, 0) / 100
        };

        vehicles.push(vehicle);
      });

      vm.vehicles = vehicles;
    });

    function download() {
      var data = [];

      angular.forEach(vm.vehicles, function (v) {
        data.push(["'" + v.number, v.captain, v.paid, v.owing]);
      });

      var contents = Papa.unparse({
        fields: ["Vehicle", "Captain", "Paid", "Owing"],
        data: data
      });

      var blob = new Blob([contents], {type: 'text/csv'});
      FileSaver.saveAs(blob, 'Entry Fees Paid Owing.csv');
    }
  }

  function FrontEndCtrl(API_URL, Papa, FileSaver, Blob, $http, event) {
    var vm = this;

    vm.event = event.data;
    vm.vehicles = null;
    vm.download = download;

    $http.get(API_URL + '/events/' + vm.event.id + '/vehicles').then(function (response) {

      var vehicles = [],
        vehicle = {};

      angular.forEach(response.data.data, function (v) {

        var checked = v.last_front_end_check;
        if (v.last_front_end_check === '0001-01-01T00:00:00Z') {
          checked = '';
        }

        vehicle = {
          number: v.number,
          checked: checked
        };

        vehicles.push(vehicle);
      });

      vm.vehicles = vehicles;
    });

    function download() {
      var data = [];

      angular.forEach(vm.vehicles, function (v) {
        data.push(["'" + v.number, v.checked]);
      });

      var contents = Papa.unparse({
        fields: ["Vehicle", "Last Front End Check"],
        data: data
      });

      var blob = new Blob([contents], {type: 'text/csv'});
      FileSaver.saveAs(blob, 'Vehicle Front End Checks.csv');
    }
  }

  function DietaryCtrl(API_URL, Papa, FileSaver, Blob, $http, event) {
    var vm = this;

    vm.event = event.data;
    vm.people = null;
    vm.download = download;

    $http.get(API_URL + '/events/' + vm.event.id + '/people?inc=VehiclePeople.Vehicle&filter=dietary').then(function (response) {

      var people = [],
        person = {};

      angular.forEach(response.data.data, function (p) {
        person = {
          name: p.first_name + ' ' + p.surname,
          diet: p.diet,
          vehicle: {
            number: ''
          },
          type: 'Entrant'
        };

        // Filter out the vehicle for this event
        for (var i = 0; i < p.vehicle_people.length; i++) {
          if (p.vehicle_people[i].vehicle.event_id == vm.event.id) {
            person.vehicle.number = p.vehicle_people[i].vehicle.number;
            break;
          }
        }

        people.push(person);
      });

      // Now get dinner guests
      $http.get(API_URL + '/events/' + vm.event.id + '/guests?inc=Vehicle').then(function (response) {
        angular.forEach(response.data.data, function (g) {
          if (g.diet !== '') {
            people.push({
              name: g.name,
              diet: g.diet,
              vehicle: {
                number: g.vehicle.number
              },
              type: 'Guest'
            });
          }
        });
      });

      vm.people = people;
    });

    function download() {
      var data = [];

      angular.forEach(vm.people, function (p) {
        data.push([p.name, "'" + p.vehicle.number, p.type, p.diet]);
      });

      var contents = Papa.unparse({
        fields: ["Name", "Vehicle", "Type", "Dietary Requirements"],
        data: data
      });

      var blob = new Blob([contents], {type: 'text/csv'});
      FileSaver.saveAs(blob, 'Dietary.csv');
    }

  }

  function IndemnityCtrl(API_URL, Papa, FileSaver, Blob, $http, event) {
    var vm = this;

    vm.event = event.data;
    vm.people = null;
    vm.download = download;
    vm.order = 'name';

    $http.get(API_URL + '/events/' + vm.event.id + '/vehicles?inc=VehiclePeople.Person').then(function (response) {

      var people = [],
        person = {};

      angular.forEach(response.data.data, function (v) {

        if (v.vehicle_people) {
          angular.forEach(v.vehicle_people, function (vp) {
            if (vp.indemnified) {
              return;
            }

            person = {
              name: vp.person.first_name + ' ' + vp.person.surname,
              vehicle_number: v.number,
              mobile_phone: vp.person.mobile_phone,
              email: vp.person.email
            };

            people.push(person);
          });
        }
      });

      vm.people = people;
    });

    function download() {
      var data = [];

      angular.forEach(vm.people, function (p) {
        data.push([p.name, "'" + p.vehicle_number, "'" + p.mobile_phone, p.email]);
      });

      var contents = Papa.unparse({
        fields: ["Name", "Vehicle", "Mobile Phone", "Email"],
        data: data
      });

      var blob = new Blob([contents], {type: 'text/csv'});
      FileSaver.saveAs(blob, 'Indemnity Report.csv');
    }

  }

  function GuestsCtrl(API_URL, Papa, FileSaver, Blob, $http, event) {
    var vm = this;

    vm.event = event.data;
    vm.people = null;
    vm.download = download;

    $http.get(API_URL + '/events/' + vm.event.id + '/guests?inc=Vehicle').then(function (response) {
      vm.people = response.data.data;
    });

    function download() {
      var data = [];

      angular.forEach(vm.people, function (p) {
        data.push(["'" + p.vehicle.number, p.name, p.diet]);
      });

      var contents = Papa.unparse({
        fields: ["Vehicle", "Name", "Dietary Requirements"],
        data: data
      });

      var blob = new Blob([contents], {type: 'text/csv'});
      FileSaver.saveAs(blob, 'Dinner Guests Report.csv');
    }
  }

  function RoadbookCtrl(API_URL, $http, event, Blob, FileSaver) {
    var vm = this;

    vm.event = event.data;
    vm.downloadWord = downloadWord;
    vm.downloadExcel = downloadExcel;

    $http.get(API_URL + '/events/' + vm.event.id + '/vehicles?inc=VehiclePeople.Person').then(function (response) {

      var people = [],
        person = {},
        groups = [],
        entrants = [],
        supports = [],
        officials = [];

      angular.forEach(response.data.data, function (v) {

        var vehicle = {
          number: v.number,
          year: v.year || '',
          make: v.make,
          model: v.vehicle_model,
          captains: [],
          crew: []
        };

        if (v.vehicle_people) {
          angular.forEach(v.vehicle_people, function (vp) {

            var participation = '';

            switch (vp.participation) {
              case 1:
                participation = 'A';
                break;
              case 2:
                participation = 'B';
                break;
            }

            var person = {
              name: vp.person.first_name + ' ' + vp.person.surname,
              participation: participation
            };

            if (vp.position === 'captain') {
              vehicle.captains.push(person);
            } else {
              vehicle.crew.push(person);
            }

            people.push(person);
          });
        }

        switch (v.type) {
          case 'entrant':
            entrants.push(vehicle);
            break;
          case 'support':
            supports.push(vehicle);
            break;
          case 'official':
            officials.push(vehicle);
            break;
        }

      });

      vm.groups = [
        {
          heading: vm.event.name + ' Vehicles',
          vehicles: entrants,
          pairs: []
        },
        {
          heading: 'Support Cars',
          vehicles: supports,
          pairs: []
        },
        {
          heading: 'Volunteer Officials',
          vehicles: officials,
          pairs: []
        }
      ];

      // Split into pairs for easily rendering into a table - couldn't get flex or float:left to honour break-page:avoid CSS
      var pair = [];
      angular.forEach(vm.groups, function (group) {
        angular.forEach(group.vehicles, function (vehicle, i) {
          if ((i % 2) === 0) {
            pair = [vehicle];
          } else {
            pair[1] = vehicle;
            group.pairs.push(pair);
          }
        });

        // Push last pair
        if (pair.length === 1) {
          group.pairs.push(pair);
        }
      });

      vm.people = people;
    });

    function downloadWord() {

      var fileName = vm.event.name + ' Roadbook ' + moment().format('DD-MM-YYYY') + '.docx';

      $http.get(API_URL + '/events/' + vm.event.id + '/roadbook.docx', {responseType: 'arraybuffer'}).then(function (response) {
        var blob = new Blob([response.data], {type: response.headers('Content-type')});
        FileSaver.saveAs(blob, fileName);
      }, function (error) {
        alert('Download error:', error);
      });
    }

    function downloadExcel() {

      var fileName = vm.event.name + ' Car and Crew Report ' + moment().format('DD-MM-YYYY') + '.xlsx';

      $http.get(API_URL + '/events/' + vm.event.id + '/carcrew.xlsx', {responseType: 'arraybuffer'}).then(function (response) {
        var blob = new Blob([response.data], {type: response.headers('Content-type')});
        FileSaver.saveAs(blob, fileName);
      }, function (error) {
        alert('Download error:', error);
      });
    }
  }

  function SafetyCtrl(API_URL, Papa, FileSaver, Blob, $http, event) {
    var vm = this;

    vm.event = event.data;
    vm.vehicles = null;
    vm.download = download;

    vm.order = '';

    $http.get(API_URL + '/events/' + vm.event.id + '/vehicles').then(function (response) {
      vm.vehicles = response.data.data;
    });

    function download() {
      var data = [];

      angular.forEach(vm.vehicles, function (v) {
        data.push(["'" + v.number, v.safety_ok ? 'Yes' : 'No', v.safety_notes]);
      });

      var contents = Papa.unparse({
        fields: ["Vehicle", "Report Returned", "Notes"],
        data: data
      });

      var blob = new Blob([contents], {type: 'text/csv'});
      FileSaver.saveAs(blob, 'Safety Checks.csv');
    }
  }

  function VirginsCtrl(API_URL, isVeterans, Papa, FileSaver, Blob, $http, event) {
    var vm = this;

    vm.event = event.data;
    vm.people = null;
    vm.download = download;

    $http.get(API_URL + '/events/' + vm.event.id + '/people?inc=VehiclePeople.Vehicle,Address').then(function (response) {

      var people = [],
        person = {};

      angular.forEach(response.data.data, function (p) {

        // only include virgins OR veterans
        if (isVeterans && p.vehicle_people.length <= 1 || !isVeterans && p.vehicle_people.length > 1) {
          return;
        }

        person = {
          first_name: p.first_name,
          last_name: p.surname,
          name: p.first_name + ' ' + p.surname,
          vehicle: {
            number: ''
          },
          position: '',
          team: '',
          mobile: p.mobile_phone,
          email: p.email,
          address: {
            suburb: '',
            state: ''
          }
        };

        if (p.address !== undefined) {
          person.address.suburb = p.address.suburb;
          person.address.state = p.address.state;
        }

        // Filter out the vehicle for this event
        for (var i = 0; i < p.vehicle_people.length; i++) {
          if (p.vehicle_people[i].vehicle.event_id === vm.event.id) {
            person.vehicle.number = p.vehicle_people[i].vehicle.number;
            person.position = p.vehicle_people[i].position;
            person.team = p.vehicle_people[i].vehicle.team;
            break;
          }
        }

        people.push(person);
      });

      vm.people = people;
    });

    function download() {
      var data = [];

      angular.forEach(vm.people, function (p) {
        data.push([p.first_name, p.last_name, "'" + p.vehicle.number, p.position, p.team]);
      });

      var contents = Papa.unparse({
        fields: ["First Name", "Last Name", "Vehicle", "Position", "Team"],
        data: data
      });

      var blob = new Blob([contents], {type: 'text/csv'});

      var fileName = isVeterans ? 'Veterans.csv' : 'Virgins.csv';

      FileSaver.saveAs(blob, fileName);
    }
  }

  function MedicalCtrl(API_URL, Papa, FileSaver, Blob, $http, event) {
    var vm = this;

    vm.event = event.data;
    vm.people = null;
    vm.download = download;
    vm.loading = true;

    $http.get(API_URL + '/events/' + vm.event.id + '/people?inc=VehiclePeople.Vehicle').then(function (response) {

      var people = [],
        person = {};

      angular.forEach(response.data.data, function (p) {
        person = {
          name: p.first_name + ' ' + p.surname,
          email: p.email,
          mobile_phone: p.mobile_phone,
          medical: p.medical,
          medical_received: '',
          nok_name: p.nok_name,
          nok_relationship: p.nok_relationship,
          nok_phone: p.nok_phone,
          vehicle: {
            number: ''
          }
        };

        // Filter out the vehicle for this event
        for (var i = 0; i < p.vehicle_people.length; i++) {
          if (p.vehicle_people[i].vehicle.event_id === vm.event.id) {
            person.vehicle.number = p.vehicle_people[i].vehicle.number;
            person.medical_received = p.vehicle_people[i].medical_received ? 'Y' : 'N';
            break;
          }
        }

        people.push(person);
      });

      people.sort(function (a, b) {
        if (a.vehicle.number.toLowerCase() < b.vehicle.number.toLowerCase()) {
          return -1;
        }
        return 1;
      });

      vm.people = people;
      vm.loading = false;
    });

    function download() {
      var data = [];

      angular.forEach(vm.people, function (p) {
        data.push(["'" + p.vehicle.number, p.name, p.email, p.mobile_phone, p.nok_name, p.nok_relationship, p.nok_phone, p.medical_received, p.medical]);
      });

      var contents = Papa.unparse({
        fields: ["Vehicle", "Name", "Email", "Mobile", "Next of Kin", "NOK Relationship", "NOK Phone", "Medical Received", "Medical Information"],
        data: data
      });

      var blob = new Blob([contents], {type: 'text/csv'});
      FileSaver.saveAs(blob, 'Medical Report ' + vm.event.name + ' at ' + moment().format('DD-MM-YYYY') + '.csv');
    }

  }

  function VaccinationsCtrl(API_URL, Papa, FileSaver, Blob, $http, event) {
    var vm = this;

    vm.event = event.data;
    vm.people = null;
    vm.download = download;
    vm.order = 'name';

    $http.get(API_URL + '/events/' + vm.event.id + '/vehicles?inc=VehiclePeople.Person').then(function (response) {

      var people = [],
        person = {};

      angular.forEach(response.data.data, function (v) {

        if (v.vehicle_people) {
          angular.forEach(v.vehicle_people, function (vp) {
            if (vp.vaccinated) {
              return;
            }

            person = {
              name: vp.person.first_name + ' ' + vp.person.surname,
              vehicle_number: v.number,
              mobile_phone: vp.person.mobile_phone,
              email: vp.person.email
            };

            people.push(person);
          });
        }
      });

      vm.people = people;
    });

    function download() {
      var data = [];

      angular.forEach(vm.people, function (p) {
        var vnPrefix = p.vehicle_number.length ? "'" : '';
        var mobPrefix = p.mobile_phone.length ? "'" : '';
        data.push([p.name, vnPrefix + p.vehicle_number, mobPrefix + p.mobile_phone, p.email]);
      });

      var contents = Papa.unparse({
        fields: ["Name", "Vehicle", "Mobile Phone", "Email"],
        data: data
      });

      var blob = new Blob([contents], {type: 'text/csv'});
      FileSaver.saveAs(blob, 'Vaccinations Report.csv');
    }

  }

}());
